
import { defineComponent } from 'vue'
import AdminPanelNavMenu from '@/components/molecules/AdminPanelNavMenu.vue'
import Pluse from '@/assets/svg/pluse.svg?inline'
import View from '@/assets/svg/view.svg?inline'
import Edit from '@/assets/svg/edit.svg?inline'
import Delete from '@/assets/svg/delete.svg?inline'
import Search from '@/assets/svg/search.svg?inline'
import SortArrow from '@/assets/svg/sort-arrow.svg?inline'
import Picture from '@/assets/test/picture.svg?inline'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import Pagination from '@/components/molecules/Pagination.vue'

export default defineComponent({
  name: 'Instructors',
  data () {
    return {
      page: 1,
      instructorsData: {},
      instructorsMeta: null,
      errorInstructors: null,
      searchData: '',
      sortData: '',
      isModalDelete: false,
      idInstructor: null
    }
  },
  components: {
    AdminPanelNavMenu,
    Pluse,
    View,
    Edit,
    Delete,
    Search,
    SortArrow,
    Picture,
    DefaultPopup,
    AlertBlock,
    Pagination
  },
  watch: {
    searchData () {
      this.getInstructors()
      this.page = 1
    }
  },
  mounted () {
    this.getInstructors()
  },
  methods: {
    createInstructor () {
      this.$router.push('/admin/create-instructors')
    },
    getInstructors (params, sort) {
      let searchParam = `?page=${this.page}&search=`
      if (this.searchData !== '') {
        searchParam = `?page=${this.page}&search=` + this.searchData
      }
      if (sort === undefined) {
        sort = 'id'
      }
      params = searchParam + '&sort=' + sort
      this.$store.dispatch('instructors/getInstructors', params).then(
        (res) => {
          this.instructorsData = res.data
          this.instructorsMeta = res.meta
        },
        (error) => {
          this.errorInstructors = error.response.data.errors
        }
      )
    },
    viewInstructor (id) {
      this.$router.push('/admin/instructor?instructor=' + id)
    },
    openDeletePoup (id) {
      this.idInstructor = id
      this.isModalDelete = true
    },
    handleCloseModalDelete () {
      this.isModalDelete = false
      this.idInstructor = null
      this.errorInstructors = null
    },
    deleteInstructor () {
      this.$store.dispatch('instructors/deleteInstructor', this.idInstructor).then(
        () => {
          this.getInstructors()
          this.isModalDelete = false
          this.idInstructor = null
        },
        (error) => {
          this.errorInstructors = error.response.data
        }
      )
    },
    editInstructor (id) {
      this.$router.push('/admin/create-instructors?edit=true&instructor=' + id)
    },
    handlePage (page) {
      this.page = page
      this.getInstructors()
    }
  }
})
